<script setup lang="ts">
const props = defineProps<{
  modelValue: number
  total: number
}>()

const emit = defineEmits<{
  (event: 'update:modelValue', value: number): void
}>()

const page = useVModel(props, 'modelValue', emit)

const DISPLAY_LENGTH = 7
const MIDDLE_PAGE = Math.floor(DISPLAY_LENGTH / 2)

const hasMoreNextPage = computed(() => page.value <= props.total - MIDDLE_PAGE - 1)
const hasMorePreviousPage = computed(() => page.value > MIDDLE_PAGE + 1)
const hasLessPageThenPaginationLength = computed(() => props.total <= DISPLAY_LENGTH)

const pageList = computed(() => {
  if (hasLessPageThenPaginationLength.value)
    return useRange(1, props.total + 1)

  else if (hasMorePreviousPage.value && hasMoreNextPage.value)
    return useRange(page.value - MIDDLE_PAGE, page.value + MIDDLE_PAGE + 1)

  else if (hasMorePreviousPage.value && page.value >= props.total - MIDDLE_PAGE)
    return useRange(props.total - DISPLAY_LENGTH + 1, props.total + 1)

  return useRange(1, DISPLAY_LENGTH + 1)
})

const next = computed(() => page.value + 1 <= props.total ? page.value + 1 : null)
const previous = computed(() => page.value > 1 ? page.value - 1 : null)
</script>

<template>
  <nav aria-label="pagination">
    <ul
      class="flex flex-wrap justify-center gap-2"
      :class="{
        'md:ml-12': page === 1,
        'md:mr-12': page === total,
      }"
    >
      <li v-if="previous !== null">
        <BasePaginationButton
          :page="previous"
          :current="page"
          button
          aria-label="Previous page"
          @click="page = previous ?? page"
        >
          <IconNavigationChevronLeft class="w-5 h-5" />
        </BasePaginationButton>
      </li>

      <li v-if="hasMorePreviousPage && !hasLessPageThenPaginationLength" class="flex items-center justify-center w-10 h-10 font-semibold bg-gray-100 rounded cursor-default font-display">
        ...
      </li>

      <li v-for="n in pageList" :key="n">
        <BasePaginationButton
          :page="n"
          :current="page"
          number
          @click="page = n"
        >
          {{ n }}
        </BasePaginationButton>
      </li>

      <li v-if="hasMoreNextPage && !hasLessPageThenPaginationLength" class="flex items-center justify-center w-10 h-10 font-semibold bg-gray-100 rounded cursor-default font-display">
        ...
      </li>

      <li v-if="next !== null">
        <BasePaginationButton
          :page="next"
          :current="page"
          button
          aria-label="Next page"
          @click="page = next ?? page"
        >
          <IconNavigationChevronRight class="w-5 h-5" />
        </BasePaginationButton>
      </li>
    </ul>
  </nav>
</template>
