<script setup lang="ts">
const props = defineProps<{
  app: AppGuide.Application
  noShadow?: boolean
}>()

const emit = defineEmits<{
  (event: 'click', ...args: any[]): void
}>()

const { t, locale } = useI18n({ useScope: 'local' })
const localePath = useLocalePath()

const title = computed(() => (locale.value === 'fr' && props.app.title_fr) || props.app.title)
const price = computed(() => {
  if (props.app.price === 0)
    return t('free')

  return new Intl
    .NumberFormat(`${locale.value}-CA`, { style: 'currency', currency: 'USD' })
    .format(props.app.price)
})

const me = useProfile()
const canShare = computed(() => !!me.value)
const canViewTrustReport = useCanViewTrustReport(props.app.uid)
</script>

<template>
  <article
    class="relative overflow-hidden bg-white rounded-xl transition ease-out hover:scale-[1.03]"
    :class="{ 'shadow-card': !noShadow }"
  >
    <header class="flex items-center px-4 pt-4 pb-3 space-x-3">
      <nuxt-img
        :src="app.icon"
        width="128"
        height="128"
        quality="100"
        :alt="`'${app.title}' official application icon`"
        class="flex-none w-16 h-16 overflow-hidden rounded-lg"
      />

      <div>
        <h2 class="font-bold font-display line-clamp-2">
          <NuxtLink
            data-x-name="app_card"
            :data-xp-uid="app.uid"
            :to="localePath({ name: 'apps-id', params: { id: `${app.uid}` } })"
            @click="emit('click')"
          >
            {{ title }}
            <span class="absolute inset-0" />
          </NuxtLink>
        </h2>

        <p class="text-xs font-semibold text-gray-500">
          <span class="overflow-hidden line-clamp-1">{{ app.developer_name }}</span>
        </p>
      </div>
    </header>

    <div class="flex px-4 pb-4">
      <div class="flex justify-between w-16 mr-3">
        <div v-if="app.platform === 'iOS' || app.match_uid" class="flex items-center justify-center text-gray-500 border border-gray-200 rounded-full w-7 h-7">
          <IconAttributesPlatformsIos class="w-3.5 h-3.5" />
        </div>
        <div v-if="app.platform === 'Android' || app.match_uid" class="flex items-center justify-center text-gray-500 border border-gray-200 rounded-full w-7 h-7">
          <IconAttributesPlatformsAndroid class="w-3.5 h-3.5" />
        </div>
      </div>
      <div class="flex items-center">
        <p class="px-2 py-1 text-sm font-bold text-blue-500 bg-blue-200 rounded-full">
          {{ price }}
        </p>
        <p v-if="app.in_app_purchases" class="text-[0.625rem] ml-2 text-gray-500 font-semibold">
          {{ t('in-app-purchase') }}
        </p>
      </div>
    </div>

    <footer class="flex items-center justify-between px-4 py-2 border-t">
      <ul v-if="canViewTrustReport" class="grid grid-cols-4 gap-3 max-w-[14.75rem] flex-none">
        <li class="flex items-center space-x-2">
          <IconScoreIconsRelevance
            class="w-3.5 h-3.5"
            :class="{
              'text-gray-300': app.relevance.score === '-1',
              'text-gray-500': app.relevance.score !== '-1',
            }"
          />
          <IconScoreIndicators :score="app.relevance.score" class="w-7 h-7" />
        </li>
        <li class="flex items-center space-x-2">
          <IconScoreIconsPrivacy class="w-3.5 h-3.5 text-gray-500" />
          <IconScoreIndicators :score="app.privacy.score" class="w-7 h-7" />
        </li>
        <li class="flex items-center space-x-2">
          <IconScoreIconsSecurity class="w-3.5 h-3.5 text-gray-500" />
          <IconScoreIndicators :score="app.security.score" class="w-7 h-7" />
        </li>
        <li class="flex items-center space-x-2">
          <IconScoreIconsSimplicity class="w-3.5 h-3.5 text-gray-500" />
          <IconScoreIndicators :score="app.simplicity.score" class="w-7 h-7" />
        </li>
      </ul>
      <p v-else class="text-sm leading-none text-violet-400 text-pretty">
        <i18n-t keypath="sign-up.text">
          <template #link>
            <NuxtLink :to="localePath('auth')" class="relative z-50 font-semibold">
              {{ t('sign-up.link') }}
            </NuxtLink>
          </template>
        </i18n-t>
      </p>

      <FeaturesShares
        v-if="canShare"
        :application="app"
        view="app-card"
      />
      <div v-else class="px-4 py-2 text-gray-300 rounded-full bg-gray-50">
        <IconNavigationSend class="w-5 h-5" />
      </div>
    </footer>
  </article>
</template>

<i18n lang="json">
{
  "en": {
    "free": "Free",
    "in-app-purchase": "In-app purchase",
    "sign-up": {
      "text": "{link} for scores",
      "link": "Sign up"
    }
  },
  "fr": {
    "free": "Gratuit",
    "in-app-purchase": "Achats intégrés",
    "sign-up": {
      "text": "{link} pour les scores",
      "link": "Inscrivez-vous"
    }
  }
}
</i18n>
