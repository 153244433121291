<script setup lang="ts">
defineProps<{
  page: number
  current: number
  number?: boolean
  button?: boolean
}>()

const emit = defineEmits<{
  (event: 'click', ...args: any[]): void
}>()
</script>

<template>
  <button
    :aria-current-value="current === page ? 'page' : 'false'"
    :aria-disabled="current === page ? 'true' : 'false'"
    class="flex items-center justify-center w-10 h-10 font-semibold border rounded font-display"
    :class="{
      'active': current === page,
      'nav-link-button': button,
      'nav-link-number': number,
    }"
    @click="emit('click', $event)"
  >
    <slot />
  </button>
</template>

<style lang="scss" scoped>
.nav-link-number {
  @apply bg-white text-violet-700 transition-colors;

  &:hover {
    @apply bg-violet-50 border-violet-200;
  }

  &.active {
    @apply bg-violet-700 text-white pointer-events-none;
  }
}

.nav-link-button {
  @apply bg-gray-50 border-gray-50 text-violet-700;
}
</style>
