<script setup lang="ts">
import { onKeyUp } from '@vueuse/core'
import { Dialog, DialogDescription, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

const { t, tm, rt, locale } = useI18n({ useScope: 'local' })
const open = useConditionsModal()
const view = ref('browse')
watch(open, () => {
  if (open.value)
    view.value = 'browse'
})

const conditions = computed(() => {
  return HEALTH_CONDITIONS
    .map(slug => ({
      slug,
      name: t(`health-conditions-full.${slug}.label`),
    }))
    .sort((a, b) => a.name.localeCompare(b.name))
})

const condition = computed(() => {
  return conditions.value.find(condition => condition.slug === view.value)
})
const listCase = computed(() => {
  if (!condition.value)
    return

  const formater = new Intl.ListFormat(`${locale.value}-CA`, { style: 'long' })
  const list = [...tm(`health-conditions-full.${condition.value.slug}.listCase`)]
    .map(x => rt(x))

  return formater.format(list)
})

const loading = ref(true)
const apps = ref<AppGuide.Summaries.Summary[]>([])
const totalApps = ref(0)
const articles = ref<AppGuide.Quill.Post[]>([])
const hideScroll = ref(false)

const pagination = reactive({
  page: 1,
  totalPages: 0,
})

async function fetchAppsPage(page: number) {
  loading.value = true

  try {
    const el = document.getElementById('conditions-modal-window')
    el?.scrollTo(0, 0)

    const summaries = await listSummaries({
      page,
      limit: 24,
      conditions: view.value,
    })

    pagination.totalPages = summaries.data.meta.totalPages
    apps.value = summaries.data.items
  }

  catch (error) {
    console.error(error)
  }

  finally {
    loading.value = false
  }
}

watch(() => pagination.page, async (page) => {
  if (page === 1 && loading.value)
    return

  await fetchAppsPage(page)
})

async function fetchContent() {
  loading.value = true

  try {
    const [summaries, posts] = await Promise.all([
      listSummaries({
        page: 1,
        limit: 24,
        conditions: view.value,
      }),
      getPostList({
        page: 1,
        limit: 8,
        language: locale.value as AppGuide.locales,
        condition: view.value,
      }),
    ])

    apps.value = summaries.data.items
    totalApps.value = summaries.data.meta.totalItems
    pagination.totalPages = summaries.data.meta.totalPages
    articles.value = posts.data.items
  }

  catch (error) {
    console.error(error)
  }

  finally {
    loading.value = false
  }
}

watch(view, async (view) => {
  const el = document.getElementById('conditions-modal-window')
  el?.scrollTo(0, 0)

  if (view === 'browse')
    return

  apps.value = []
  articles.value = []
  pagination.page = 1
  pagination.totalPages = 0
  totalApps.value = 0
  await fetchContent()
})

onKeyUp('Escape', () => {
  if (open.value)
    open.value = false
})

function gotoCondition(condition: string) {
  view.value = condition
}
</script>

<template>
  <TransitionRoot
    :show="open"
    as="template"
    @before-enter="hideScroll = true"
    @after-enter="hideScroll = false"
    @before-leave="hideScroll = true"
    @after-leave="hideScroll = false"
  >
    <Dialog
      data-x-feature="health_conditions_modal"
      class="relative z-modal"
      @close="open = false"
    >
      <TransitionChild
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-violet-800/90" aria-hidden="true" />
      </TransitionChild>

      <div class="fixed inset-x-0 top-0 h-14 md:h-18 grid grid-cols-[4rem,1fr,4rem] px-6">
        <TransitionChild
          enter="delay-500 duration-300 ease-out overflow-hidden"
          enter-from="opacity-0 -translate-x-full"
          enter-to="opacity-100 translate-x-0"
          leave="duration-150 ease-in overflow-hidden"
          leave-from="opacity-100 translate-x-0"
          leave-to="opacity-0 -translate-x-full"
        >
          <button
            data-x-action="close"
            data-x-name="health_conditions"
            aria-label="Close modal"
            type="button"
            class="flex items-center justify-center w-16 text-white transition-colors hover:text-blue-500 h-14 md:h-18"
            @click="open = false"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 6L4 16M4 16L14 26M4 16L28 16"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span class="absolute inset-0" />
          </button>
        </TransitionChild>

        <TransitionChild
          enter="delay-300 duration-300 ease-out overflow-hidden"
          enter-from="opacity-0 translate-y-full"
          enter-to="opacity-100 translate-y-0"
          leave="duration-150 ease-in overflow-hidden"
          leave-from="opacity-100 translate-y-0"
          leave-to="opacity-0 translate-y-full"
        >
          <DialogTitle class="flex items-center justify-center font-bold text-white h-14 md:h-18">
            {{ t('modal-title') }}
          </DialogTitle>
        </TransitionChild>
      </div>

      <div
        id="conditions-modal-window"
        class="fixed inset-0 top-14 md:top-18"
        :class="hideScroll ? 'overflow-hidden' : 'overflow-y-auto'"
      >
        <TransitionChild
          enter="duration-300 ease-out overflow-hidden"
          enter-from="opacity-0 translate-y-full"
          enter-to="opacity-100 translate-y-0"
          leave="duration-200 ease-in overflow-hidden"
          leave-from="opacity-100 translate-y-0"
          leave-to="opacity-0 translate-y-full"
        >
          <div class="flex min-h-full bg-gray-50 rounded-tl-2xl md:rounded-tl-3xl">
            <DialogPanel v-if="condition" data-x-section="condition" class="w-full pt-6 pb-14">
              <div class="mx-6 mb-10 lg:mx-20">
                <UiBreadcrumbs
                  :items="[
                    { id: 'apps', name: t('breadcrumb.apps') },
                    { id: 'health-conditions', name: t('breadcrumb.health-conditions'), click: () => view = 'browse' },
                    { id: 'health-condition', name: condition.name },
                  ]"
                />
              </div>

              <div id="condition-view" class="mx-6 mb-12 lg:mx-20">
                <div class="condition-view__contents">
                  <h3 class="font-bold font-display mb-4 text-3xl lg:text-4.5xl title">
                    {{ t('condition.header.title') }}
                    <span
                      class="text-red-500"
                    >{{ listCase }}</span>
                  </h3>

                  <DialogDescription class="description">
                    {{ t(`health-conditions-full.${condition.slug}.description`) }}
                  </DialogDescription>
                </div>

                <img
                  :src="`/assets/health-conditions/${condition.slug}.svg`"
                  :alt="`Image representing ${condition.name}`"
                  width="128"
                  height="128"
                  class="w-16 h-16 sm:w-32 sm:h-32"
                >
              </div>

              <BlockArticleViewTrack
                v-if="articles.length"
                :title="t('condition.articles.title')"
                title-tag="h3"
                :posts="articles"
                :feature-condition="view"
                class="mt-12 mb-14"
                @click="open = false"
              />

              <h3 class="mx-6 mb-6 text-sm font-bold lg:mx-20 lg:text-lg lg:leading-none font-display">
                {{ t('condition.view-grid.title') }}
                <span v-if="totalApps > 0">({{ totalApps }})</span>
              </h3>
              <div v-if="apps.length && !loading" class="grid grid-cols-1 gap-6 px-6 lg:px-20 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                <ApplicationCard
                  v-for="app in (apps as any[])"
                  :key="app.id"
                  :app="app.application"
                  @click="open = false"
                />
              </div>
              <section v-if="loading" class="mx-6 mt-12 lg:mx-20">
                <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                  <div v-for="n in 24" :key="`app-card-skeleton-${n}`" class="h-[189px] bg-gray-100 animate-pulse rounded-lg" />
                </div>
              </section>

              <BasePagination
                v-if="pagination.totalPages > 1 && !loading"
                v-model="pagination.page"
                :total="pagination.totalPages"
                class="mt-8"
              />
            </DialogPanel>

            <DialogPanel v-else data-x-section="list" class="w-full px-6 pt-6 pb-14 lg:px-20">
              <div class="mx-auto mb-10 max-w-7xl">
                <UiBreadcrumbs
                  :items="[
                    { id: 'apps', name: t('breadcrumb.apps') },
                    { id: 'health_conditions', name: t('breadcrumb.health-conditions') },
                  ]"
                />
              </div>

              <h3 class="font-bold md:text-center font-display text-3xl sm:text-4.5xl mb-16">
                {{ t('browse.title[0]') }}
                <span class="text-red-500">{{ t('browse.title[1]') }}</span>
              </h3>

              <ul class="grid grid-cols-2 gap-6 mx-auto sm:gap-8 max-w-7xl sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
                <li v-for="{ name, slug } in conditions" :key="slug" class="contents">
                  <button
                    data-x-name="health_condition"
                    data-x-action="goto"
                    :data-xp-condition="slug"
                    type="button"
                    class="relative flex flex-col items-center gap-3 ease-out px-2 py-6 rounded-lg transform transition-transform hover:scale-[1.03] shadow-fields bg-white"
                    @click="gotoCondition(slug)"
                  >
                    <img
                      :src="`/assets/health-conditions/${slug}.svg`"
                      :alt="`Image representing ${name}`"
                      width="64"
                      height="64"
                    >
                    <span
                      class="flex items-center justify-center flex-grow flex-shrink font-bold leading-5 text-center font-display"
                    >{{ name }}</span>
                  </button>
                </li>
              </ul>
            </DialogPanel>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped lang="scss">
#condition-view {
  @apply flex relative justify-between;

  img {
    @apply flex-none absolute right-0;
  }

  @screen sm {
    @apply gap-x-12;

    img {
      @apply static;
    }
  }

  .condition-view__contents {
    .title {
      min-height: 4rem;
      padding-right: 6rem;

      @screen sm {
        min-height: 0;
        padding-right: 0;
      }
    }
  }
}
</style>

<i18n src="../../../locales/health-conditions-full.json" />

<i18n lang="json">
{
  "en": {
    "modal-title": "Health Conditions",
    "breadcrumb": {
      "apps": "Apps",
      "health-conditions": "Health Conditions"
    },
    "browse": {
      "title": [
        "Browse apps by ",
        "health conditions"
      ]
    },
    "condition": {
      "header": {
        "title": "App reviews for ",
        "alt": "an icon representing {condition}"
      },
      "articles": {
        "title": "Related articles"
      },
      "view-grid": {
        "title": "Relevant apps"
      }
    }
  },
  "fr": {
    "modal-title": "Conditions de santé",
    "breadcrumb": {
      "apps": "Applis",
      "health-conditions": "Conditions de santé"
    },
    "browse": {
      "title": [
        "Parcourir les applications par ",
        "condition de santé"
      ]
    },
    "condition": {
      "header": {
        "title": "Révisions d’applis pour ",
        "alt": "un icon représentant {condition}"
      },
      "articles": {
        "title": "Articles connexes"
      },
      "view-grid": {
        "title": "Applis pertinentes"
      }
    }
  }
}
</i18n>
