<script setup lang="ts">
const props = defineProps<{
  title: string
  titleTag?: string
  posts: AppGuide.Quill.Post[]
  featureCondition: string
}>()

const emit = defineEmits<{
  (event: 'click', ...args: any[]): void
}>()

const featured = computed(() => props.posts.length >= 1 ? props.posts[0] : null)
const postList = computed(() => [...props.posts].slice(1))
</script>

<template>
  <UtilsTack :title="title" :title-tag="titleTag">
    <div v-if="featured">
      <BlockArticleCardFeatured
        :post="featured"
        :feature-condition="featureCondition"
        @click="emit('click', $event)"
      />
    </div>

    <template v-if="postList.length">
      <div
        v-for="post in postList"
        :key="post.id"
      >
        <BlockArticleCardFeaturedSmall
          :post="post"
          :feature-condition="featureCondition"
          @click="emit('click', $event)"
        />
      </div>
    </template>
  </UtilsTack>
</template>
