<script setup lang="ts">
defineProps<{
  title: string
  titleTag?: string
}>()

const track = ref()
const { x, arrivedState } = useScroll(track, { behavior: 'smooth' })

const gap = 16
const child_width = ref(0)
const overflow = ref(true)

useResizeObserver(track, (entries) => {
  overflow.value = (track.value.scrollWidth - track.value.offsetWidth) > 0
  const firstChild = entries[0].target.firstElementChild as HTMLElement
  child_width.value = firstChild.offsetWidth
})

const next = () => {
  x.value += child_width.value + gap
}

const previous = () => {
  x.value -= child_width.value + gap
}
</script>

<template>
  <div>
    <div class="flex items-center justify-between px-6 lg:px-20">
      <component :is="titleTag || 'h2'" class="text-sm font-bold lg:text-lg lg:leading-none font-display">
        {{ title }}
      </component>

      <div v-if="overflow" class="flex space-x-2">
        <button
          type="button"
          :disabled="arrivedState.left"
          aria-label="Scroll to the left"
          class="flex items-center justify-center w-8 h-8 transition-colors bg-white rounded-full shadow-fields text-violet-500 disabled:text-gray-300 hover:bg-blue-500 hover:text-white disabled:pointer-events-none"
          @click="previous"
        >
          <IconNavigationChevronLeft class="w-4 h-4" />
        </button>
        <button
          type="button"
          :disabled="arrivedState.right"
          aria-label="Scroll to the right"
          class="flex items-center justify-center w-8 h-8 transition-colors bg-white rounded-full shadow-fields text-violet-500 disabled:text-gray-300 hover:bg-blue-500 hover:text-white disabled:pointer-events-none"
          @click="next"
        >
          <IconNavigationChevronRight class="w-4 h-4" />
        </button>
      </div>
    </div>

    <div ref="track" class="flex max-w-full gap-4 px-6 pt-6 pb-4 lg:px-20 hide-scrollbar">
      <slot />
    </div>
  </div>
</template>
